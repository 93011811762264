const AccreditedInvestorParagraphs = {
  'j.1': '(j.1) an individual who beneficially owns financial assets (which term excludes real estate) having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $5,000,000',
  'j': '(j) an individual who alone beneficially owns financial assets (which term excludes real estate) having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $1,000,000',
  'j_s': '(j) an individual who with a spouse, beneficially owns financial assets (which term excludes real estate) having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $1,000,000',
  'k': '(k) an individual whose net income before taxes exceeded $200,000 in each of the two most recent calendar years and who reasonably expects to exceed that net income level in the current calendar year',
  'k_s': '(k) an individual whose net income before taxes combined with that of a spouse exceeded $300,000 in each of the two most recent calendar years and who reasonably expects to exceed that net income level in the current calendar year',
  'l': '(l) an individual who alone has net assets of at least $5,000,000',
  'l_s': '(l) an individual who with a spouse has net assets of at least $5,000,000',
}

export default AccreditedInvestorParagraphs
