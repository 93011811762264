<template>
  <div class="email-request">
    <NavBack/>
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Accredited Member</div>
      <div class="main has-background-white is-relative">
        <div class="section-title has-text-weight-semibold has-text-centered">ACCREDITED INVESTOR CERTIFICATE</div>
        <div class="section-content">
          I, <span class="has-text-weight-semibold">{{fullName}}</span>, hereby represent and warrant to addy Technology Corp, its subsidiaries, and the issuer of
          securities through the addy platform (collectively, the "addy Group") that, as of the date hereof and the date of the closing of the purchase of any securities
          through the addy platform, I belong to the class of purchasers corresponding to one of the boxes checked below, and I understand that the addy Group will rely
          upon the truthfulness and accuracy of this representation and warranty:
        </div>
        <section class="paragraphs mt-5 pl-3">
          <b-checkbox
            v-model="paragraph"
            type="is-blue"
            :native-value="key"
            v-for="(value, key) in categories"
            :key="key"
          >{{value}}</b-checkbox>
        </section>
        <section class="consent has-text-weight-semibold mt-5">
          <b-checkbox v-model="consent" type="is-blue">
            I will notify the addy Group if I cease to belong to the class of purchasers chosen above
          </b-checkbox>
        </section>
      </div>
      <BottomButtons
        nextButtonText="Continue"
        backButtonText="Cancel"
        :nextDisabled="nextButtonDisabled"
        :loading="loadingNextButton"
        :showSteps="false"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import AccreditedInvestorParagraphs from '@utils/data/accredited_investor_paragraphs.js'
import { submitAccreditedParagraph } from '@api/membership'

export default {
  data() {
    return {
      paragraph: [],
      categories: Object.freeze(AccreditedInvestorParagraphs),
      consent: false,
      loadingNextButton: false,
    }
  },
  watch: {
    paragraph: (value) => {
      if (value.length > 1) value.shift()
    }
  },
  computed: {
    fullName() {
      const investor = JSON.parse(localStorage.getItem('investor'))
      return `${investor.firstName} ${investor.lastName}`
    },
    nextButtonDisabled() {
      return !this.paragraph.length || !this.consent
    },
    nextStepUrl() {
      const nextStepUrl = this.$route.query.nextStepUrl
      // nextStepUrl is present when coming from the prop buy flow
      // American accredited skips incomeReporting
      if (nextStepUrl && nextStepUrl.includes('incomeReporting')) {
        const propertyId = nextStepUrl.split('/')[2]
        return `/buy/amount/${propertyId}`
      }
      return nextStepUrl
    },
    returnUrl() {
      return this.$route.query.returnUrl
    },
  },
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
  },
  methods: {
    generatePayload() {
      let category = this.paragraph[0]
      const withSpouse = !!category.match(/.+_s$/)
      category = withSpouse ? category.split('_')[0] : category

      return {
        category,
        spousal_status: withSpouse
      }
    },
    goNext() {
      this.loadingNextButton = true

      const payload = this.generatePayload()
      submitAccreditedParagraph(payload).then((result) => {
        this.loadingNextButton = false
        if (!result.success) return

        if (this.nextStepUrl) { // In the property purchase flow
          this.$router.push(decodeURIComponent(this.nextStepUrl))
        } else {
          // In the membership purchase flow
          // returnUrl is used to differentiate the membership buy flow started from the portfolio page and dashboard
          this.$router.push(`/membership/confirmation/accredited?returnUrl=${this.returnUrl}`)
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
.email-request {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 80px;
  .has-padding {
    padding: 0 27px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 0 14px 20px;
      .section-title {
        padding-top: 22px;
        padding-bottom: 22px;
      }
      .section-content {
        line-height: 22px;
      }
      .paragraphs {
        font-size: 14px;
        .b-checkbox {
          margin-bottom: 10px;
        }
      }
      .consent {
        .b-checkbox {
          ::v-deep .control-label {
            padding-left: 16px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .email-request {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
